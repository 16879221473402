<template>
	<div class="playlistCreate">

		<h1 class="text-center">Gib deinem Workout einen Namen!</h1>
		<p class="text-center">Wie soll dein Workout heißen? Idealerweise bezeichnest du es nach dem entprechenden Anwendungszweck.</p>

		<v-text-field v-model="playlistName" name="" placeholder="Name des Workouts" id="" type="text" required>
		</v-text-field>

		<v-btn :disabled="!playlistName || playlistName.length == 0" class="button button--primary" @click="continuePlaylistCreate">Zur Übungsauswahl</v-btn>
	</div>
</template>

<script>
	export default {
		name: 'PlaylistCreateName',
		data() {
			return {
				playlistName: "",
				playlistData: null,
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				this.playlistData = this.$store.getters.getTemporaryPlaylist;
				this.playlistName = this.playlistData.name || "";
			},
			continuePlaylistCreate() {
				this.$store.dispatch('updateTemporaryPlaylist', {
					name: this.playlistName,
				});
				this.$router.push("/playlists/create/select-exercises");
			}
		}
	}
</script>

<style lang="scss">
	.playlistCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		h1 {
			margin-top: 31px;
			margin-bottom: 18px;
		}

		p {
			margin-bottom: 41px;
		}

		.button {
			margin-top: auto;
		}
	}
</style>